<template>
  <div>
    <b-row class="content-header mb-2">
      <b-col class="col-md-6">
        <h2 class="content-header-title float-left pr-1 mb-0">Hatchery</h2>
      </b-col>
    </b-row>

    <b-card no-body class="cardBody">
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          :to="{
            name: 'apps-hatchery-add',
          }"
          class="mr-2 mt-3 custom-btn"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          Add
        </b-button>
      </div>
      <div v-if="hatchery_list.length > 0">
        <div v-bind:class="[hatchery_list.length === 1 ? ' m-2' : ' row m-5']">
          <div v-for="item in hatchery_list" v-bind:key="item.id">
            <div v-if="hatchery_list.length === 1">
              <b-col md="12" class="single-hatchery-view p-0">
                <div
                  class="top-section p-2 d-flex justify-content-between"
                  v-bind:class="[
                    status === active ? 'activeClass' : 'inactiveClass',
                  ]"
                >
                  <div class="single-item">
                    <b-img
                      :src="
                        require('@/assets/images/svg/hatchery/single-hatchery.svg')
                      "
                      class="svg-img"
                      height="30px"
                      width="30px"
                    />

                    <div class="card-text">
                      <h5 class="ml-2">{{ item.hatchery_name }}</h5>
                      <span class="ml-2">Created date:</span>
                      <span> {{ setDateFormat(item.created_at) }}</span>
                    </div>
                  </div>
                  <b-dropdown
                    variant="link"
                    no-caret
                    right
                    class="chart-dropdown ml-1"
                    toggle-class="p-0"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="24"
                        class="text-white cursor-pointer"
                      />
                    </template>
                    <b-dropdown-item>
                      <router-link
                        class="text-secondary ml-1"
                        :to="{
                          name: 'apps-hatchery-edit',
                          params: { hatcheryId: `${item.id}` },
                        }"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                      </router-link>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <!-- <b-card class="hatchery-single-view"> -->
                <div class="single-list-view p-2">
                  <b-row>
                    <b-col md="3">
                      <b-row>
                        <b-col md="2">
                          <b-img
                            :src="
                              require('@/assets/images/svg/hatchery/setter.svg')
                            "
                            class="svg-img"
                          />
                        </b-col>
                        <b-col>
                          <div class="itemText">Setter</div>
                          <div class="itemHeading">
                            {{ item.setterCount }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="3">
                      <b-row>
                        <b-col md="2">
                          <b-img
                            :src="
                              require('@/assets/images/svg/hatchery/setter.svg')
                            "
                            class="svg-img"
                          />
                        </b-col>
                        <b-col>
                          <div class="itemText">Hatcher</div>
                          <div class="itemHeading">
                            {{ item.hatcherCount }}
                          </div>
                        </b-col>
                      </b-row></b-col
                    >
                    <b-col md="3">
                      <b-row>
                        <b-col md="2">
                          <b-img
                            :src="
                              require('@/assets/images/svg/hatchery/created.svg')
                            "
                            class="svg-img"
                          />
                        </b-col>
                        <b-col>
                          <div class="itemText">Created By</div>
                          <div class="itemHeading">{{ item.createdBy }}</div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <!-- <b-col md="3">
											<b-row>
												<b-col md="2">
													<b-img
														:src="
															require('@/assets/images/svg/hatchery/user.svg')
														"
														class="svg-img"
													/>
												</b-col>
												<b-col>
													<div class="itemText">Assign</div>

													<span
														class="b-avatar pull-up badge-secondary rounded-circle"
														style="
															margin-left: calc(-5.1px);
															margin-right: calc(-5.1px);
															width: 26px;
															height: 26px;
														"
														><span class="b-avatar-img"
															><img
																src="@/assets/images/avatars/1.png"
																alt="avatar"
														/></span>
													</span>

													<span
														class="b-avatar pull-up badge-secondary rounded-circle"
														style="
															margin-left: calc(-5.1px);
															margin-right: calc(-5.1px);
															width: 26px;
															height: 26px;
														"
														><span class="b-avatar-img"
															><img
																src="@/assets/images/avatars/5.png"
																alt="avatar"
														/></span>
													</span>
													<span
														class="align-self-center cursor-pointer mb-0 more-text"
													>
														+ {{ item.AssignTo }} MORE</span
													>
												</b-col>
											</b-row>
										</b-col> -->
                  </b-row>
                </div>
                <!-- </b-card> -->
              </b-col>
            </div>
            <div v-else>
              <b-col md="4">
                <div class="card-block">
                  <!-- <div class="d-flex justify-content-end">
                  <feather-icon icon="MenuIcon" size="18" />
                </div> -->
                  <div
                    class="top-section"
                    v-bind:class="[
                      item.status === 'Active'
                        ? 'activeClass'
                        : 'inactiveClass',
                    ]"
                  >
                    <div class="cardText">
                      <h5>{{ item.hatchery_name }}</h5>
                      <p>{{ item.address }}</p>
                      <p class="card-text font-small-3">
                        {{ setDateFormat(item.created_at) }}
                      </p>
                      <b-img
                        :src="
                          require('@/assets/images/svg/hatchery/card-top.svg')
                        "
                        class="svg-img"
                      />
                    </div>
                  </div>
                  <div class="py-1">
                    <div class="list-item">
                      <b-row>
                        <b-col class="col-md-5 item-text"> Setter </b-col>
                        <b-col class="item-right-text">{{
                          item.setterCount
                        }}</b-col>
                      </b-row>
                    </div>
                    <div class="list-item">
                      <b-row>
                        <b-col class="col-md-5 item-text"> Hatcher</b-col>
                        <b-col class="item-right-text">{{
                          item.hatcherCount
                        }}</b-col>
                      </b-row>
                    </div>

                    <!-- <div class="list-item">
										<b-row>
											<b-col class="col-md-5 item-text"> Assigned to </b-col>
											<b-col>
												<span
													class="align-self-center cursor-pointer mb-0 more-text"
												>
													{{ item.AssignTo }}</span
												>
											</b-col>
										</b-row>
									</div> -->
                    <div class="list-item">
                      <b-row>
                        <b-col class="col-md-5 item-text"> Created by </b-col>
                        <b-col class="item-right-text">{{
                          item.createdBy
                        }}</b-col>
                      </b-row>
                    </div>
                    <!-- <div class="list-item">
										<b-row>
											<b-col class="col-md-5 item-text"> Location </b-col>
											<b-col class="item-right-text">{{
												item.hatchery.hatchery_location
											}}</b-col>
										</b-row>
									</div> -->
                    <div class="mx-1 mt-1">
                      <div class="view-section">
                        <b-link
                          class="view-text"
                          :to="{
                            name: 'apps-hatchery-edit',
                            params: { hatcheryId: `${item.id}` },
                          }"
                        >
                          Edit</b-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="d-flex flex-column align-items-center py-5">
        <b-img
          :src="require('@/assets/images/svg/hatchery/no-hatchery.svg')"
          class="mr-2"
        />
        <h4 class="mt-2 Secondary">There is No list of Hatchery</h4>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTabs,
  BTab,
  BCardText,
  BFormDatepicker,
  BFormSelect,
  BListGroup,
  BListGroupItem,
  BImg,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";

import axiosIns from "@/libs/axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BCardText,
    BTabs,
    BTab,
    vSelect,
    BFormDatepicker,
    BFormSelect,
    BListGroup,
    BListGroupItem,
    BImg,
  },
  data() {
    return {
      selected: null,
      hatchery_id: null,
      hatchery_list: [],
      hatchery: null,
      hatchery_machine: null,
      length: null,
      hatchery_name: null,
      companyId: null,
    };
  },
  methods: {
    getUserHatchery() {
      let companyId = this.companyId;
      let url = "";
      if (companyId) {
        url = `web/company/${companyId}/getUserHatcheryList`;
      } else {
        url = `web/getUserHatcheryList`;
      }
      axiosIns
        .get(url)
        .then((response) => {
          this.hatchery_list = response.data.user_hatchery_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
  },
  created() {
    this.companyId = this.$route.params.companyId;
    this.getUserHatchery();
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/pages/hatchery.scss";
@import "@core/scss/vue/libs/vue-select.scss";

@import "@core/scss/vue/pages/_common.scss";
</style>
